import servicesImg1 from "../../../assets/images/servicesImg1.png";
import servicesImg2 from "../../../assets/images/servicesImg2.png";
import servicesImg3 from "../../../assets/images/servicesImg3.png";

const Services = () => {
  return (
    <div className="container mx-auto py-10">
      {/* Section Title */}
      <h1 className="text-center text-4xl font-semibold mb-16">OUR SERVICES</h1>

      {/* Services Section with Images and Text */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
        {/* Service 1 */}
        <div className="text-center">
          <img
            src={servicesImg1}
            alt="Service 1"
            className="w-full h-[80vh] object-cover mb-4" // Add margin bottom for spacing
          />
          <h2 className="text-xl font-semibold mb-4 text-[#3c0008]">PERSONALIZATION</h2>
          <p className="text-gray-600 mb-4">
            Emboss select bags, luggage, belts, leather accessories, and items from the pet’s collection with initials.
          </p>
          <a href="#" className="underline block mb-4 text-[#3c0008]">Discover The Collection</a>
        </div>

        {/* Service 2 */}
        <div className="text-center">
          <img
            src={servicesImg2}
            alt="Service 2"
            className="w-full h-[80vh] object-cover mb-4" // Add margin bottom for spacing
          />
          <h2 className="text-xl font-semibold mb-4 text-[#3c0008]">PACKAGING</h2>
          <p className="text-gray-600 mb-4">
            Choose between our online exclusive tote or boutique shopping bag to give your order the perfect finish.
          </p>
          <a href="#" className="text-[#3c0008] underline block mb-4">Explore Gucci’s Packaging</a>
        </div>

        {/* Service 3 */}
        <div className="text-center">
          <img
            src={servicesImg3}
            alt="Service 3"
            className="w-full h-[80vh] object-cover mb-4" // Add margin bottom for spacing
          />
          <h2 className="text-xl font-semibold mb-4 text-[#3c0008]">COLLECT IN STORE</h2>
          <p className="text-gray-600 mb-4">
            Order online and collect your order from your preferred Gucci boutique.
          </p>
          <a href="#" className="text-[#3c0008] underline block mb-4">Discover How</a>
        </div>
      </div>
    </div>
  );
};

export default Services;
