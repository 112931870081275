import featuredImg from '../../../assets/images/featuredImg.png'; // Replace with your image path

const FeaturedBanner = () => {
  return (
    <div className="my-16 mx-4 md:mx-8"> 
      {/* Featured Text */}
      <h2 className="text-xl font-bold text-center mb-16">FEATURED</h2>

      {/* Featured Items */}
      <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-8">
        {/* First Div with Image */}
        <div className="relative w-full md:w-1/2">
          <img
            src={featuredImg}
            alt="Featured"
            className="w-full h-auto object-cover"
          />
        </div>

        {/* Second Div with Text */}
        <div className="flex items-center justify-center w-full md:w-1/2 bg-white p-4 rounded">
          <div className="text-center">
            <h3 className="text-3xl font-semi-bold mb-4">WE WILL ALWAYS HAVE LONDON</h3>
            <p className="text-lg mb-4 text-gray-700">
              The latest chapter in Sabato De Sarno’s ever-evolving vision unfolds in a new campaign by Nan Goldin with Kelsey Lu and Debbie Harry.
            </p>
            <a href="#" className="text-sm font-bold text-[#3c0008] underline">DISCOVER MORE</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBanner;
