import '@fortawesome/fontawesome-free/css/all.min.css';
import contact from './contactUs.jpeg';

const ContactUs = () => {
  return (
    <div className="bg-gray-100 w-screen">
      {/* Info Section */}
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
          {/* Contact Info */}
          <div className="w-full lg:w-1/2 bg-white shadow-lg items-center justify-between text-center rounded-2xl"> {/* Added text-center here */}
            <div className="p-6">
              <i className="fas fa-comments text-4xl mb-4 text-yellow-500"></i>
              <h2 className="text-3xl font-bold mb-4 text-gray-800">Get in Touch with Us</h2>
              <p className="text-lg mb-6 text-gray-600">
                Whether you're interested in our exquisite pashmina products or need assistance, our team is ready to support you.
                Reach out to us for inquiries or guidance, and let us help you find the perfect piece.
              </p>
              <div className="flex flex-col space-y-4">
                <a
                  href="tel:+918126680000"
                  className="block px-6 py-3 bg-[#3c0008] ref-color rounded-full text-lg font-semibold transition-colors duration-300 hover:bg-[#3c0008] text-center"
                >
                  CALL US: +91 81266 80000
                </a>
                <a  
                  href="mailto:Layaliartmail@gmail.com"
                  className="block px-6 py-3 bg-[#3c0008] ref-color rounded-full text-lg font-semibold transition-colors duration-300 hover:bg-[#3c0008] text-center"
                >
                  EMAIL: LAYALIARTMAIL@GMAIL.COM
                </a>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <img
              src={contact}
              alt="Contact us"
              className="w-full h-64 lg:h-96 object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

      {/* Contact Form & Map Section */}
      <div className="bg-white py-12">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-24">
            {/* Contact Form Section */}
            <div className="w-full lg:w-1/2">
              <div className="bg-gray-50 shadow-lg">
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-center mb-8 text-gray-800">Contact a Specialist</h3>
                  <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name:</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="mt-1 px-4 py-2 border border-gray-300 rounded-full w-full focus:ring-2 focus:ring-maroon-500"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">E-mail:</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="mt-1 px-4 py-2 border border-gray-300 rounded-full w-full focus:ring-2 focus:ring-maroon-500"
                        required
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number:</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="mt-1 px-4 py-2 border border-gray-300 rounded-full w-full focus:ring-2 focus:ring-maroon-500"
                        required
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label htmlFor="message" className="block text-sm font-medium text-gray-700">How may we assist you?</label>
                      <textarea
                        id="message"
                        name="message"
                        rows="4"
                        className="mt-1 px-4 py-2 border border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-maroon-500"
                        required
                      ></textarea>
                    </div>
                    <div className="sm:col-span-2 text-center">
                      <button
                        type="submit"
                        className="px-8 py-3 bg-[#3c0008] ref-color rounded-full text-lg font-semibold transition-colors duration-300 hover:bg-[#3c0008]"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Map Section */}
            <div className="w-full lg:w-1/2">
              <div className="bg-gray-50 shadow-lg">
                <div className="p-6">
                  <h3 className="text-2xl font-bold mb-4 text-gray-800">Our Ware House and Corporate Office</h3>
                  <div className="aspect-w-16 aspect-h-9">
                  <iframe
  title="Map"
  className="w-full h-72 lg:h-80 rounded-lg"
  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d649832.7272810738!2d78.15195071544372!3d29.056740816513102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQ4JzUzLjIiTiA3OcKwMDEnMzkuMyJF!5e0!3m2!1sen!2s!4v1728812945396!5m2!1sen!2s&zoom=3"
  style={{ border: 0 }}
  allowFullScreen
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
