import '../../../assets/css/main.css'; // Import external CSS
import logo from './logo.png';

const Footer = () => {
  return (
    <>
      <footer className="back lg:h-96 lg:flex items-center justify-center">
        <div className="w-full max-w-6xl mx-auto px-4 lg:px-0 flex flex-col lg:flex-row justify-around items-center text-white text-center lg:text-left">
          {/* Logo Section */}
          <div className="w-32 flex justify-center items-center py-4 lg:py-0">
            <img src={logo} alt="Logo" className="mx-auto" />
          </div>

          {/* Navigation Section */}
          <div className="flex gap-12 flex-col lg:flex-row items-center lg:items-start justify-between text-center lg:text-left">
            <div className="lg:flex flex-col items-center lg:items-start">
              <h2 className="font-bold mb-4 text-lg ref-color">NAVIGATION</h2>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:text-gray-300 nav ref-color">
                    HOME
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-300 nav ref-color">
                    GALLERY
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-300 nav ref-color">
                    ABOUT US
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-300 nav ref-color">
                    CONTACT US
                  </a>
                </li>
              </ul>
            </div>

            {/* Location Section */}
            <div className="lg:flex flex-col items-center lg:items-start mt-6 lg:mt-0">
              <h2 className="font-bold mb-4 text-lg ref-color">OUR LOCATION</h2>
              <p className="leading-relaxed ref-color">
                Imli Asmat Khan Ghair Ali khan,
              </p>
              <p className="leading-relaxed ref-color">Rampur, 244901, India</p>
            </div>
          </div>
        </div>
      </footer>

      <div className="bg-gray-800 text-white py-4 text-center text-sm">
        COPYRIGHT © 2024 LAYALI ART - ALL RIGHTS RESERVED.
      </div>
    </>
  );
};

export default Footer;
