import BannerHome from "../components/uncommon/bannerHome/BannerHome";
import CollectionBanner from "../components/uncommon/collectionBanner/CollectionBanner.jsx";
import FeaturedBanner from "../components/uncommon/featured/Featured.jsx";
import ServicesBanner from "../components/uncommon/services/Services.jsx";

const Home = () => {
  return (
    <>
      <BannerHome />
      <CollectionBanner />
      <FeaturedBanner />
      <ServicesBanner />
    </>
  );
};

export default Home;
