import { useState } from 'react';
import '../../../assets/css/main.css'; 
import logo from './logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="fixed z-50 top-0 left-0 right-0 back shadow-md py-12 px-4 sm:px-6">
      <div className="flex items-center justify-between">
        {/* Hamburger menu for mobile */}
        <button
          onClick={toggleMenu}
          className="lg:hidden text-gray-600 focus:outline-none ref-color"
        >
          <i className="fas fa-bars text-2xl"></i>
        </button>

        {/* Centered logo */}
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <img className="h-24 w-24" src={logo} alt="Logo" />
        </div>

        {/* Desktop menu */}
        <div className="hidden lg:flex items-center space-x-12 pl-14">
          <a href="/" className="nav ref-color text-xl">
            <i className="fas fa-home"></i>
          </a>
          <a href="/gallery" className="nav ref-color text-xl">
            <i className="fas fa-images"></i>
          </a>
          <a href="/about" className="nav ref-color text-xl">
            <i className="fas fa-info-circle"></i>
          </a>
          <a href="/contact" className="nav ref-color text-xl">
            <i className="fas fa-envelope"></i>
          </a>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`lg:hidden nav-menu fixed inset-y-0 left-0 w-64 bg-white shadow-lg transform ${
          isMenuOpen ? '' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out back`}
      >
        <div className="p-6">
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-gray-600 focus:outline-none ref-color"
          >
            <i className="fas fa-times text-2xl"></i>
          </button>
          <ul className="space-y-4 mt-8">
            <li>
              <a href="/" className="nav ref-color block text-xl py-2">
                <i className="fas fa-home mr-2"></i> Home
              </a>
            </li>
            <li>
              <a href="/gallery" className="nav ref-color block text-xl py-2">
                <i className="fas fa-images mr-2"></i> Gallery
              </a>
            </li>
            <li>
              <a href="/about" className="nav ref-color block text-xl py-2">
                <i className="fas fa-info-circle mr-2"></i> About
              </a>
            </li>
            <li>
              <a href="/contact" className="nav ref-color block text-xl py-2">
                <i className="fas fa-envelope mr-2"></i> Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
