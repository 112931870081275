import ContactUs from "../components/uncommon/contactUs/ContactUs"

const Contact = () => {
  return (
    <>
      <ContactUs/>
    </>
  )
}

export default Contact
