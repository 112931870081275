import bannerImg from "../../../assets/images/homeBannerImg.png";

const BannerHome = () => {
  return (
    <div
      className="relative bg-cover bg-center h-[90vh]"  // Set height to 90vh
      style={{ backgroundImage: `url(${bannerImg})` }}
    >
      {/* Container for centering text and buttons */}
      <div className="absolute inset-0 flex flex-col justify-center items-center text-center px-4">
        {/* Text Section */}
        <div className="mb-8 ref-color">
          <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-4">
            LAYALI ART
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-2">
            DISCOVER THE ELEGANCE OF PASHMINA TODAY
          </p>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl mb-6">
            Pure Pashmina, Crafted with Passion <br />
            Where Tradition Meets Style
          </p>
        </div>

        {/* Buttons Section */}
        <div className="flex flex-col sm:flex-row justify-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
          <a
            href="tel:+918126680000"
            className="w-full sm:w-auto px-6 py-3 bg-[#3c0008] ref-color rounded-full text-base sm:text-lg font-semibold transition-colors duration-300 hover:bg-[#54000b] text-center"
          >
            CALL US: +91 81266 80000
          </a>
          <a
            href="mailto:Layaliartmail@gmail.com"
            className="w-full sm:w-auto px-6 py-3 bg-[#3c0008] ref-color rounded-full text-base sm:text-lg font-semibold transition-colors duration-300 hover:bg-[#54000b] text-center"
          >
            EMAIL: LAYALIARTMAIL@GMAIL.COM
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerHome;
