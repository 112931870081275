import colImg1 from "../../../assets/images/collectionImg1.png"; // Replace with your image path
import colImg2 from "../../../assets/images/collectionImg2.png"; // Replace with your image path
import colImg3 from "../../../assets/images/collectionImg3.png";

const CollectionBanner = () => {
  return (
    <div className="mx-4 md:mx-8 my-8">
      <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-8">
        <div className="relative w-full md:w-1/2">
          <img
            src={colImg1}
            alt="Fall Winter 2024"
            className="w-full h-[80vh] object-cover" // Set a fixed height for consistency
          />
          <div className="absolute inset-0 flex flex-col items-center justify-end pb-10 text-center">
            <p className="text-2xl font-bold mb-4 text-[#f0ba59]">Fall Winter 2024</p>
            <a
              href="tel:+918126680000"
              className="w-full sm:w-auto px-6 py-3 bg-[#3c0008] ref-color rounded-full text-base sm:text-lg font-semibold transition-colors duration-300 hover:bg-[#54000b] text-center text-white"
            >
              Explore Collection
            </a>
          </div>
        </div>
        <div className="relative w-full md:w-1/2">
          <img
            src={colImg2}
            alt="Shoes"
            className="w-full h-[80vh] object-cover" // Set a fixed height for consistency
          />
          <div className="absolute inset-0 flex flex-col items-center justify-end pb-10 text-center">
            <p className="text-2xl font-bold mb-4 text-[#f0ba59]">Fall Winter 2024</p>
            <a
              href="tel:+918126680000"
              className="w-full sm:w-auto px-6 py-3 bg-[#3c0008] ref-color rounded-full text-base sm:text-lg font-semibold transition-colors duration-300 hover:bg-[#54000b] text-center text-white"
            >
              Explore Collection
            </a>
          </div>
        </div>
      </div>
      <div className="relative w-full mt-8">
        <img
          src={colImg3}
          alt="Fall Winter 2024"
          className="w-full h-[80vh] object-cover" // Set a fixed height for consistency
        />
        <div className="absolute inset-0 flex flex-col items-center justify-end pb-10 text-center">
          <p className="text-3xl md:text-4xl font-bold mb-4 text-[#f0ba59]">Fall Winter 2024</p>
          <a
            href="tel:+918126680000"
            className="w-full sm:w-auto px-6 py-3 bg-[#3c0008] ref-color rounded-full text-base sm:text-lg font-semibold transition-colors duration-300 hover:bg-[#54000b] text-center text-white"
          >
            Explore Men's Collection
          </a>
        </div>
      </div>
    </div>
  );
};

export default CollectionBanner;
